<template>
  <v-form @submit.prevent class="forgot-form">
    <p class="input-label">Email <span>*</span></p>

    <v-text-field
      name="email"
      v-model="email"
      type="email"
      prepend-inner-icon="mail"
      outlined
    ></v-text-field>
    <div class="btn-container">
      <div style="display: flex;">
        <router-link to="login" class="back-btn">
          <p id="back-btn">Back</p>
        </router-link>
      </div>
      <v-btn class="submit-btn" color="primary" type="button" @click="submit"
        >Submit</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import { ForgotPassword } from "@/api/auth/forgot-password"
export default {
  data() {
    return {
      email: ""
    }
  },
  methods: {
    async submit() {
      await ForgotPassword({ email: this.email })
      this.$router.push("/auth/login")
    }
  }
}
</script>

<style scoped>
.forgot-form {
  font-size: 20px;
}
.input-label {
  letter-spacing: normal;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}
::v-deep .v-input__slot {
  background: #fff !important;
  border-radius: 10px;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.submit-btn {
  font-size: 20px;
  letter-spacing: normal;
}
.back-btn {
  align-self: center;
  display: flex;
  font-size: 20px;
  color: var(--v-grey-base);
}
.back-btn p {
  margin: 0;
}
@media only screen and (max-width: 767px) {
  #forgot-password-title {
    margin-bottom: 30px;
    font-size: 22px !important;
  }
  .btn-container {
    margin-top: 0;
  }
  .input-label {
    font-size: 18px;
  }
  .forgot-password p {
    font-size: 18px;
  }
  ::v-deep .v-btn__content {
    font-size: 18px;
  }
  .login-btn {
    width: 120px;
    height: 50px !important;
    padding: 10px 40px;
    border-radius: 10px;
  }
}
</style>
